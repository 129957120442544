<template>
<b-card>
  <validation-observer ref="form">
  <b-form @submit.prevent="modeEdit ? update() : store()">
        <b-row>
            <b-col md="6" xl="6" class="mb-1">
                <b-form-group label="Nombre del Banco">
                  <b-form-input v-model="formData.name" maxLength="25"/>
                </b-form-group>

                <b-form-group label="Nro de Cuenta">
                  <b-form-input v-model="formData.account" maxLength="25"/>
                </b-form-group>

                <b-form-group label="Cédula">
                  <b-form-input v-model="formData.id_card" maxLength="15"/>
                </b-form-group>

                <b-form-group label="Tipo de Cuenta">
                    <b-form-input v-model="formData.type" maxLength="10"/>
                </b-form-group>

                <b-form-group label="Teléfono">
                    <b-form-input v-model="formData.phone" maxLength="15"/>
                </b-form-group>

                  <b-form-group label="Estatus">
                    <v-select
                    v-model="formData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[{id: 1, name: 'Activo'}, {id: 2, name: 'Inactivo'}]"
                    label="name"
                    :reduce="(item) => item.id"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-col class="mt-2 d-flex justify-content-end">
            <b-button
                type="submit"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            <feather-icon :icon="'EditIcon'" size="16" class="mr-50"/>
                Aceptar
            </b-button>
        </b-col>
  </b-form>
  </validation-observer>
</b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BMedia, BAvatar, BFormSelect
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
localize('es', es)
import vSelect from 'vue-select'

export default {
  components: {
    BMedia, 
    BAvatar,
    BCard, 
    BRow, 
    BCol, 
    BForm, 
    BFormGroup, 
    BFormInput, 
    BButton, 
    BInputGroup, 
    BInputGroupPrepend, 
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      required,
      formData: {
        status: 1
      }
    }
  },
  computed: {
    modeEdit() {
      if( router.currentRoute.name === 'bank-edit' ) {
        return router.currentRoute.params.id
      }
    },
  },
  methods: {
    async store() {
      if (!await this.$refs.form.validate()) {
        return
      }

      await store.dispatch('bank/store', this.formData)
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se agrego el banco exitosamente')
        this.$router.push({ name: 'banks' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    async update() {
      if (!await this.$refs.form.validate()) {
        return
      }

      await store.dispatch('bank/update', { id: this.modeEdit, formData: this.formData })
      .then(() => {
        this.message('checkCircleIcon', 'success', 'Se actualizo el banco exitosamente')
        this.$router.push({ name: 'banks' })
      })
      .catch((error) => {
        this.message('AlertCircleIcon', 'danger', error.response.data.message)
      })
    },
    message(icon, variant, text) {
      this.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Respuesta',
        icon: icon,
        variant: variant,
        text: text,
      }})
    },
    async getData(){
      this.formData = await store.dispatch('bank/show', this.modeEdit)
    },
  },
  mounted(){
    if(this.modeEdit) {
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>